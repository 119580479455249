import React from "react";
import "./App.css";
import "./index.css";
import logo from "../src/images/Lisa-removebg-preview.png";
import lisa from "../src/images/smile.png";
import lisa_con from "../src/images/smilr.png";
import comp from "../src/images/cpmp.png";
import git from "../src/images/git.png";
import react_logo from "../src/images/react_logo.png";
import flutter_logo from "../src/images/flutter.png";
import html_logo from "../src/images/html.png";
import css_logo from "../src/images/css.png";
import js_logo from "../src/images/javascript.png";
import sql_logo from "../src/images/mysql.png";
import php_logo from "../src/images/php.png";
import firebase_logo from "../src/images/firebase.png";
import figma_logo from "../src/images/figma.png";
import python_logo from "../src/images/python.png";
import canva_logo from "../src/images/canva_logo.png";
import hire from "../src/images/Group 8.png";
import tiktok from "../src/images/TikTok.png";
import email from "../src/images/Circled Envelope.png";
import instagram from "../src/images/Instagram.png";
import phone from "../src/images/Phone.png";
import linkedin from "../src/images/LinkedIn Circled.png";
import django from "../src/images/django.png";
import tailwind from "../src/images/tailwind.png";
import phonehold from "../src/images/phoneholder.jpg";
import laptop from "../src/images/laptops.jpg";
import CV from "../src/Aileen_Akpalu_CV.pdf";
import { Navbar } from "flowbite-react";
function App() {
  return (
    <div class="mx-2">
      <section id="home" class="xl:h-screen h-auto">
        {/* main page */}
        <Navbar fluid={true} rounded={true} class="w-auto">
          <Navbar.Brand href="#">
            <img src={logo} className="md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Navbar.Link
              class=" block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#home"
            >
              Home
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent "
              href="#education"
            >
              Education
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent "
              href="#about"
            >
              Skills
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#projects"
            >
              Projects
            </Navbar.Link>
            <Navbar.Link
              class="block py-2 pr-4 pl-3 text-cutepink  rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-cutepink  md:p-0 dark:text-cutepink md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              href="#contact"
            >
              Contact
            </Navbar.Link>
          </Navbar.Collapse>
        </Navbar>

        <h1 class="mb-4 mt-8 text-4xl font-extrabold tracking-tight leading-none text-cutepink md:text-5xl lg:text-6xl dark:text-cutepink text-center">
          Hi, I am Aileen Akpalu
        </h1>
        <p class="text-white text-center xl:text-xl font-bold">
          Software Engineer and Content creator
        </p>
        <div class=" mx-auto container grid grid-cols-3 flex justify-items-center md:pt-20 pt-10 gap-3">
          <div class="md:col-span-1 col-span-3 text-center md:pt-28">
            <h1 class="text-3xl font-bold md:text-4xl lg:text-5xl text-white">
              Designer
            </h1>
            <p class="text-white">
              UI/UX Designer with passion for creating beautiful user
              experiences
            </p>
          </div>
          <div class=" md:col-span-1 col-span-3 blob w-full flex justify-center bg-cutepink dark:bg-cutepink">
            <img src={lisa} className="mx-3 w-72 " alt="Lisa Logo" />
          </div>
          <div class=" md:col-span-1 col-span-3 text-center md:pt-28">
            <h1 class="text-3xl font-bold md:text-4xl lg:text-5xl text-white">
              {"<"}Coder{">"}
            </h1>
            <p class="text-white">
              Full Stack Developer who focuses on clean and efficient code{" "}
            </p>
          </div>
        </div>
      </section>
      <section id="education" class="h-auto mx-2 text-center">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div>
          <p class="mb-4 tracking-tight leading-none text-cutepink text-xs dark:text-cutepink">
            educational background
          </p>
          <h1 class="mb-4 md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink">
            Education
          </h1>

          <div class="mt-8 text-justify">
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
            <div class="grid grid-cols-12 divide-x-2 divide-cutepink gap-4 xl:h-48 mb-1 mt-1">
              <div class="col-span-6 justify-self-end">
                <p class="font-normal text-gray-500">Nov 2015-May 2018</p>
              </div>
              <div class="col-span-6 ">
                <p class="font-bold text-white xl:text-2xl text-xl ml-2">
                  Achimota School
                </p>
                <p class="font-normal text-white xl:text-lg text-[15px] ml-2">
                  General Science,WASSCE
                </p>
                <ul class="font-light text-white text-sm ml-3 list-inside list-disc text-justify">
                  <li>
                    {" "}
                    Basketball Team: Competed in Accra InterSchools, securing an
                    impressive 2nd place.
                  </li>
                  <li>
                    {" "}
                    Achimota Cadets: Honored to serve as Platoon Leader for my
                    house during my final year.
                  </li>
                  <li>
                    {" "}
                    American Field Service Club: Actively contributed and
                    selected as the publicity coordinator in my final year.
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
            <div class="grid grid-cols-12 divide-x-2 divide-cutepink gap-4 xl:h-48 mt-1 mb-1">
              <div class="col-span-6 justify-self-end text-xl text-white text-left">
                <p class="font-bold text-white xl:text-2xl text-xl">
                  Ashesi University
                </p>
                <p class="font-normal text-white xl:text-lg text-[15px]">
                  Computer Science,Bsc
                </p>
                <ul class="font-light text-white text-sm list-inside list-disc text-justify">
                  <li>
                    {" "}
                    Math Tutor: Mentored first-year students in Calculus during
                    my second year.
                  </li>
                  <li>
                    {" "}
                    Berekuso Ashesi Leo Club: Served as Secretary and later Vice
                    President.
                  </li>
                  <li>
                    {" "}
                    Football Player: Active in Red Army Women's team, captained
                    in third year, and became coach in final year. Led the team
                    to victory in Ashesi Women's Premier League and maiden
                    Ashesi Women's Champions League.{" "}
                  </li>
                </ul>
              </div>
              <div class="col-span-6">
                <p class="font-normal text-gray-500 ml-2">Sep 2018-May 2022</p>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
            <div class="grid grid-cols-12 divide-x-2 divide-cutepink gap-4 xl:h-48 mt-1 mb-1">
              <div class="col-span-6 justify-self-end">
                <p class="font-normal text-gray-500">Jan 2024-Feb 2025</p>
              </div>
              
              <div class="col-span-6 ">
                <p class="font-bold text-white xl:text-2xl text-xl ml-2">
                  Kingston University London
                </p>
                <p class="font-normal text-white xl:text-lg text-[15px] ml-2">
                  Software Engineering with Management Studies, MSc
                </p>
                <ul class="font-light text-white text-sm ml-3 list-inside list-disc text-justify">
                <li>
                    {" "}
                    Focused on advanced software engineering concepts and management skills.
                  </li>
                  <li>
                    {" "}
                    Participated in various software development projects and research.
                  </li>
                  <li>
                    {" "}
                    Agile Product Development, Software Quality Engineering, Software Architecture, and Business in Practice modules.
                  </li>
                  <li>
                    {" "}
                    Dissertation: AfroNutro: An AI-Driven Nutritional Assistant Focused on African Nutrition for Personalized Health Management.
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <div class="bg-gray-400 rounded-full h-10 w-10 border-none ml-4"></div>
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="w-auto xl:h-screen h-auto">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div class="mx-auto container grid grid-cols-12 flex justify-items-center gap-4">
          <div class="col-span-12 md:col-span-6 md:text-justify text-center">
            <img
              src={comp}
              alt="comp"
              className=" md:w-88 w-56 md:-ml-6 ml-20"
            ></img>
            <h1 class="md:text-6xl text-3xl font-bold text-cutepink  md:text-left md:block hidden">
              Skills{"&"}
              <br></br>Experience
            </h1>
            <h1 class="md:text-6xl text-3xl font-bold text-cutepink  md:text-left md:hidden block text-center">
              Skills{"&"}Experience
            </h1>
            <p class="md:leading-7 leading-normal text-white pt-2 md:text-lg text-xs">
              The scope of my work is largely focused on frontend development
              for both web and mobile development.<br></br>Backend development
              is focused on PHP,MySQL,Firebase or Django. <br></br>
              For my content creation, I use CapCut and Canva to edit my videos.
              I have been creating food,travel and lifestyle content since 2021
              and have over 14000+ followers on Tiktok and Instagram.
            </p>
          </div>
          <div class="flex justify-center col-span-12 md:col-span-6 md:pr-4 pr-10 md:pt-1 pt-2">
            <div class="grid grid-cols-11 ">
              <div class="col-start-6 col-span-4 ...">
                <img
                  src={git}
                  alt="Git logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={react_logo}
                  alt="React logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={flutter_logo}
                  alt="Flutter logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-2 col-span-3 ...">
                <img
                  src={html_logo}
                  alt="HTML logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-6 col-span-3 ...">
                <img
                  src={js_logo}
                  alt="CSS logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-10 col-span-3 ...">
                <img
                  src={css_logo}
                  alt="JS logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={django}
                  alt="Firebase logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={php_logo}
                  alt="PHP logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-2 col-span-3 ...">
                <img
                  src={firebase_logo}
                  alt="SQL logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-6 col-span-3 ...">
                <img
                  src={python_logo}
                  alt="Python logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-10 col-span-3 ...">
                <img
                  src={sql_logo}
                  alt="Djanho logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-4 col-span-3 ...">
                <img
                  src={figma_logo}
                  alt="Figma logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
              <div class="col-start-8 col-span-3 ...">
                <img
                  src={canva_logo}
                  alt="Canva logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>

              <div class="col-start-6 col-span-4 ...">
                <img
                  src={tailwind}
                  alt="Tailwind logo"
                  class="md:w-24 md:h-24 w-12 h-12"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="projects" class="h-auto mx-2 text-center">
        <Navbar fluid={true} rounded={true} class="bg-transparent">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <div>
          <p class="mb-4 tracking-tight leading-none text-cutepink text-xs dark:text-cutepink">
            some of my recent works
          </p>
          <h1 class="mb-4 md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink">
            Projects
          </h1>

          <div className="container mx-auto pr-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="AfroNutro" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">AfroNutro</h2>
                <p className="text-white mt-2">
                  An AI-Driven Nutritional Assistant Focused on African Nutrition for Personalized Health Management.
                </p>
                <p className="text-cutepink mt-2">Technologies: Python, TensorFlow, Django</p>
                <a href="https://afronutro-frontend.vercel.app" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="EOA Online" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">EOA Online</h2>
                <p className="text-white mt-2">
                  A Shopify site built for a small-scale clothing line.
                </p>
                <p className="text-cutepink mt-2">Technologies: Shopify, Liquid, CSS</p>
                <a href="https://eoaline.com/" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="KriffeOnline" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">KriffeOnline</h2>
                <p className="text-white mt-2">
                  An e-commerce and ticketing platform that is yet to be launched into mainstream.
                </p>
                <p className="text-cutepink mt-2">Technologies: React, Context API, Tailwind CSS</p>
                <a href="https://kriffeonline.com" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="Kalangu" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Kalangu</h2>
                <p className="text-white mt-2">
                  An e-learning platform for pre-university students.
                </p>
                <p className="text-cutepink mt-2">Technologies: ReactJS, Tailwind CSS</p>
                <a href="https://main.de96qgus5lwlh.amplifyapp.com/" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={phonehold} alt="Lamped" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Lamped</h2>
                <p className="text-white mt-2">
                  A mobile application for reminding people to take medications.
                </p>
                <p className="text-cutepink mt-2">Technologies: React Native, Firebase</p>
                <a href="https://www.figma.com/file/67QHm7dPmi5rXLgDuD2Lj8/Lamber?type=design&node-id=0%3A1&mode=design&t=iIMQ8tdALPtg8wPO-1" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="Lamber" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Lamber</h2>
                <p className="text-white mt-2">
                  A mobile and web application for handling emergency response.
                </p>
                <p className="text-cutepink mt-2">Technologies: React, Node.js, MongoDB</p>
                <a href="https://github.com/melwinkle/capstone" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="Events Management" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Events Management</h2>
                <p className="text-white mt-2">
                  A web application to create and manage events for event organizers. Customers can also buy tickets for events.
                </p>
                <p className="text-cutepink mt-2">Technologies: Django, React, PostgreSQL</p>
                <a href="https://github.com/melwinkle/eventsmanagements" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={laptop} alt="Business Management" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Business Management</h2>
                <p className="text-white mt-2">
                  A web application for store owners to manage and track inventory, employees, and stock.
                </p>
                <p className="text-cutepink mt-2">Technologies: PHP, MySQL, Bootstrap</p>
                <a href="https://github.com/melwinkle/businessmanagements" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div>
              {/* <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <img src={phonehold} alt="Alarm Clock" className="w-full h-64 object-cover rounded-t-lg" />
                <h2 className="text-3xl font-semibold text-white mt-4">Alarm Clock</h2>
                <p className="text-white mt-2">
                  A mobile application design for alarm clock. Wireframes available.
                </p>
                <p className="text-cutepink mt-2">Technologies: Figma</p>
                <a href="https://www.figma.com/file/FHIZO5poEq9m1TZFAxUTmb/Alarm_Clock?type=design&node-id=0%3A1&mode=design&t=C9mfwBq2xzhqNpZo-1" target="_blank" rel="noreferrer" className="text-cutepink mt-2 block">View Project</a>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section id="contact" class="xl:h-screen h-auto relative mx-2">
        <Navbar fluid={true} rounded={true} class="bg-transparent ">
          <Navbar.Brand href="#">
            <img src={logo} className="mr-3 md:h-32 h-16 " alt="Lisa Logo" />
          </Navbar.Brand>
        </Navbar>
        <h1 class="mb-4  md:text-6xl text-5xl font-bold tracking-tight leading-none text-cutepink dark:text-cutepink text-center">
          Contact Me
        </h1>

        <div class="grid grid-cols-6 mt-2">
          <div class="col-start-1 col-end-3 justify-self-center ...">
            <a href="https://www.tiktok.com/@melwinkle?_t=8YCFW7N2jcU&_r=1">
              <img src={tiktok} alt="Tiktok" class="md:h-18 h-16"></img>
            </a>{" "}
          </div>
          <div class="col-end-7 col-span-2 justify-self-center ...">
            <a href="mailto:adzo.lisa@gmail.com">
              <img src={email} alt="Email" class="md:h-18 h-16"></img>
            </a>
          </div>
          <div class="col-start-2 col-span-4 justify-self-center ...">
            <a
              href={CV}
              download="Aileen_Akpalu_CV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={hire} alt="Hire me" class="md:h-24 h-20"></img>
            </a>
          </div>
          <div class="col-start-1 col-span-6 justify-self-center ...">
            {" "}
            <img src={lisa_con} alt="Smiling Bimtoji" class="h-80  "></img>
          </div>
          <div class="col-start-2 col-span-4 justify-self-center ...">
            <a href="+447909296843">
              <img src={phone} alt="Phone" class="md:h-20 h-16"></img>
            </a>
          </div>
          <div class="col-start-1 col-end-3 justify-self-center ...">
            <a href="https://instagram.com/lisa.akpalu?igshid=YTY2NzY3YTc=">
              <img src={instagram} alt="Instagram" class="md:h-20 h-16"></img>
            </a>
          </div>
          <div class="col-end-7 col-span-2 justify-self-center ...">
            <a href="https://www.linkedin.com/in/aileen-akpalu-256b25171">
              <img src={linkedin} alt="LinkedIN" class="md:h-20 h-16"></img>
            </a>
          </div>
        </div>
      </section>
      <script src="../path/to/flowbite/dist/flowbite.js"></script>
    </div>
  );
}

export default App;
